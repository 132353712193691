html, body, .App {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.container {
    display: grid;
}

.cell {
    width: 8px;
    height: 8px;
    margin: 0.25px;
    cursor: pointer;
}

